export const getBusinessDocumentTitle = (name: string): string => {
  let title = "-";
  if (name === "cac") {
    title = "Certificate of Incorporation";
  } else if (name === "particulars-of-directors") {
    title = "Status Report / CO7";
  } else if (name === "utility-bill" || name === "bank-statement") {
    title = "Proof of Address";
  }
  return title;
};
