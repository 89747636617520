import React, { useEffect, useState } from "react";

import { useAuth } from "contexts/auth-context";
import { BiSolidError } from "react-icons/bi";
import { useIdleTimer } from "react-idle-timer";
import { pluralizeText } from "utils";

import { Button } from "ui/button";
// import { IIdleCheckerProps } from "./types";
import { Modal } from "ui/modal";
import { defaultModalCss } from "ui/modal/styles";
import { Heading3, Paragraph3 } from "ui/typography";

const IDLE_COUNT_DOWN = 300000; // 5 minutes
const PROMPT_COUNT_DOWN = 60000; // 1 minute
const IDLE_TIMER_THROTTLE = 500; // split second
const ONE_SECOND = 1000; // 1 second
const COUNTER_INCREMENT_VALUE = 1;
const INITIAL_COUNT = 0;

export const IdleChecker = (/* props: IIdleCheckerProps */) => {
  const { logOut } = useAuth();
  const [isIdle, setIsIdle] = useState(false);
  const [activityCounter, setActivityCounter] = useState(INITIAL_COUNT);
  const [timeRemaining, setTimeRemaining] = useState(INITIAL_COUNT);
  const [isOpenPrompt, setIsOpenPrompt] = useState(false);

  const onIdle = () => {
    setIsIdle(true);
    logOut();
  };

  const onActive = () => {
    setIsIdle(false);
  };

  const onAction = () => {
    setActivityCounter(
      (activityCounter) => activityCounter + COUNTER_INCREMENT_VALUE
    );
  };

  const onPrompt = () => {
    setIsOpenPrompt(true);
  };

  const idleTimer = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    onPrompt,
    timeout: IDLE_COUNT_DOWN,
    promptBeforeIdle: PROMPT_COUNT_DOWN,
    throttle: IDLE_TIMER_THROTTLE,
  });

  const stayLoggedIn = () => {
    setIsOpenPrompt(false);
    idleTimer.reset();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(Math.ceil(idleTimer.getRemainingTime() / ONE_SECOND));
    }, IDLE_TIMER_THROTTLE);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      <div className="hidden">
        <h1>Idle Check</h1>
        <br />
        <p>Current Status: {isIdle ? "idle" : "active"}</p>
        <p>Action Events: {activityCounter}</p>
        <p>{timeRemaining} seconds remaining</p>
      </div>

      <Modal
        isOpen={isOpenPrompt}
        setIsOpen={setIsOpenPrompt}
        allowOutsideClick={false}
        modalCss={{ ...defaultModalCss, padding: "0" }}
        position="center"
      >
        <div className="p-6 max-w-sm text-center flex justify-center flex-col items-center">
          <div>
            <BiSolidError className="text-red-600 text-6xl" />
          </div>

          <Heading3
            className="mt-4"
            fontWeight="700"
            textColor="#111827"
          >
            Login expiring...
          </Heading3>

          <div className="mt-3 text-fade">
            <Paragraph3
              className="mb-1 text-[15px]"
              fontWeight="500"
              textColor="#9ca3af"
            >
              Your login session is about to expire.
            </Paragraph3>
            <Paragraph3
              lineHeight="21px"
              className="text-[15px]"
              fontWeight="500"
              textColor="#9ca3af"
            >
              You will be automatically logged out in&nbsp;
              <span className="text-red-600 whitespace-nowrap">
                {timeRemaining} {pluralizeText("second", timeRemaining, "s")}
              </span>
            </Paragraph3>
          </div>
          <div className="mt-6 flex space-x-4">
            <Button
              type="button"
              onClick={stayLoggedIn}
              variant="outline"
            >
              Stay logged in
            </Button>
            <Button
              type="button"
              onClick={logOut}
            >
              Logout
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
