import React, { memo } from "react";

import { Environment } from "components/common/environment";
import { IdleChecker } from "components/common/idle-checker";
import { useAuth } from "contexts/auth-context";
import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "routes/constants";

import { IAuthGuardProps } from "./types";

export const AuthGuard = memo((props: IAuthGuardProps) => {
  const { children, redirectPath } = props;
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return (
      <Navigate
        to={redirectPath ?? ROUTES.LOGIN_PAGE}
        replace
      />
    );
  }

  return (
    <>
      <>{children}</>
      <Outlet />
      <Environment
        showOnDev
        showOnProd
      >
        <IdleChecker />
      </Environment>
    </>
  );
});
