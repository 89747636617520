import React, { createContext, useContext, useState } from "react";

import { ILoginResponse, IUserData } from "api/auth/types";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/constants";
import { storage } from "utils/storage";

import { customInfoToast } from "ui/custom-toast";

import { IAuthContextProps, IAuthProviderProps } from "./types";

const LOGOUT_DELAY = 1000;
const LOGOUT_TOAST_TIMER = 1000;
const DEFAULT_TOKEN_EXPIRY = 0;
const DEFAULT_USER_DATA: IUserData = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  userName: "",
  userType: "",
  token: "",
  tokenExpiry: DEFAULT_TOKEN_EXPIRY,
};

const AuthContext = createContext<IAuthContextProps>({
  isAuthenticated: false,
  userData: DEFAULT_USER_DATA,
  logIn: () => {
    return;
  },
  logOut: () => {
    return;
  },
});

export const AuthProvider = (props: IAuthProviderProps) => {
  const { children } = props;
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    !!storage.authToken
  );
  const [userData, setUserData] = useState<IUserData>(
    storage.userData ?? DEFAULT_USER_DATA
  );

  const logIn = (response: ILoginResponse) => {
    const { token, userData, status } = response;

    if (status !== "success") return;
    const responseUserData: IUserData = {
      id: userData._id,
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      userName: userData.username,
      userType: userData.userType,
      token,
      tokenExpiry: jwtDecode(token)?.exp || DEFAULT_TOKEN_EXPIRY,
    };
    storage.authToken = token;
    storage.userData = responseUserData;
    setUserData(responseUserData);
    setIsAuthenticated(true);
    navigate(ROUTES.DASHBOARD_PAGE);
  };

  const logOut = () => {
    customInfoToast(
      { message: "Logging out...", isLoading: true },
      {
        autoClose: LOGOUT_TOAST_TIMER,
      }
    );
    setTimeout(() => {
      storage.authToken = null;
      storage.userData = null;
      setIsAuthenticated(false);
      navigate(ROUTES.LOGIN_PAGE);
    }, LOGOUT_DELAY);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userData, logIn, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
