import React from "react";

import { truncateText } from "utils";

import { Paragraph2 } from "ui/typography";

import { MAX_LINK_TEXT_LENGTH } from "../consts";
import { IRouteIconAndTextProps } from "./types";

export const RouteIconAndText = (props: IRouteIconAndTextProps) => {
  const { icon, isSideBarCollapsed, text } = props;

  return (
    <div className="flex items-center cursor-pointer">
      <div className="mr-2">{icon}</div>
      <Paragraph2
        lineHeight="20px"
        textColor="#ffffff"
        className={`transition-opacity duration-300 ease-out ${
          isSideBarCollapsed ? "opacity-0 delay-200" : "opacity-100 delay-500"
        }`}
        style={{
          visibility: isSideBarCollapsed ? "hidden" : "visible",
          transitionDelay: isSideBarCollapsed ? "0ms" : "300ms",
        }}
      >
        {truncateText(text, MAX_LINK_TEXT_LENGTH)}
      </Paragraph2>
    </div>
  );
};
