import { SystemStyleObject } from "@chakra-ui/react";

export const defaultModalCss: SystemStyleObject = {
  width: "400px",
  borderRadius: "12px",
  padding: "24px",
  boxShadow: "0px 8px 8px -4px #10182808",

  "@media screen and (max-width: 500px)": {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
  },
};
