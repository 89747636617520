import React, { createContext, useContext, useState } from "react";

import { TExpandedRoutes } from "ui/sidebar/types";

import { ISideBarContextProps, ISideBarProviderProps } from "./types";

const SideBarContext = createContext<ISideBarContextProps>({
  isSideBarCollapsed: false,
  handleToggleSidebar: () => {
    return;
  },
  handleToggleSubRoutes: () => {
    return;
  },
  handleCloseAllOpenSubRoutes: () => {
    return;
  },
  expandedRoutes: {},
});

export const SideBarProvider = (props: ISideBarProviderProps) => {
  const { children } = props;
  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState(false);
  const [expandedRoutes, setExpandedRoutes] = useState<TExpandedRoutes>({});

  const handleToggleSubRoutes = (path: string) => {
    setExpandedRoutes(() => {
      const prevPathState = expandedRoutes[path];
      const newState = Object.keys(expandedRoutes).reduce(
        (acc, key) => {
          acc[key] = false;
          return acc;
        },
        {} as Record<string, boolean>
      );
      newState[path] = !prevPathState;
      return newState;
    });
  };

  const handleCloseAllOpenSubRoutes = () => {
    setExpandedRoutes({});
  };

  const handleToggleSidebar = () => {
    setIsSideBarCollapsed((prev) => {
      if (!prev) {
        handleCloseAllOpenSubRoutes();
      }
      return !prev;
    });
  };

  return (
    <SideBarContext.Provider
      value={{
        isSideBarCollapsed,
        handleToggleSidebar,
        handleToggleSubRoutes,
        handleCloseAllOpenSubRoutes,
        expandedRoutes,
      }}
    >
      {children}
    </SideBarContext.Provider>
  );
};

export const useSideBar = () => useContext(SideBarContext);
