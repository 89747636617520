import React from "react";

import { ReactComponent as EarnipayLogo } from "assets/icons/sidebar/earnipay-logo-nav.svg";
import { ReactComponent as SideBarLeftIcon } from "assets/icons/sidebar/sidebar-left.svg";
import { useSideBar } from "contexts/sidebar-context";

// import { ISideBarHeaderProps } from "./types";
import { Paragraph1, Paragraph4 } from "ui/typography";

export const SideBarHeader = (/* props: ISideBarHeaderProps */) => {
  const { isSideBarCollapsed, handleToggleSidebar } = useSideBar();

  return (
    <div
      className={`flex items-center justify-between h-[42px] px-4 ${isSideBarCollapsed ? "flex-col h-[82px] gap-[18px]" : ""}`}
    >
      <div
        className={`w-full max-w-[122px] flex items-center gap-3 ${isSideBarCollapsed ? "justify-center" : ""}`}
      >
        <div>
          <EarnipayLogo />
        </div>
        {!isSideBarCollapsed && (
          <div className="flex flex-col gap-1">
            <Paragraph1 textColor="#ffffff">Earnipay</Paragraph1>
            <Paragraph4 textColor="#9F9F9F">Control</Paragraph4>
          </div>
        )}
      </div>
      <div
        onClick={handleToggleSidebar}
        className="cursor-pointer"
      >
        <SideBarLeftIcon
          className={`${isSideBarCollapsed ? "rotate-180" : ""}`}
        />
      </div>
    </div>
  );
};
