// Define the type for the input object (epColor)
type ColorObject = { [key: string]: string };

// Define the type for the transformed output object
type TransformedColorObject = { [key: string]: { value: string } };

// Transform function with TypeScript types
function transformColors(colorObj: ColorObject): TransformedColorObject {
  const transformed: TransformedColorObject = {};

  for (const [key, value] of Object.entries(colorObj)) {
    transformed[key] = { value };
  }

  return transformed;
}

type EpColorObject = Record<string, string>;

function convertToColorScales(
  colors: EpColorObject
): Record<string, Record<string, string>> {
  const colorScales: Record<string, Record<string, string>> = {};

  Object.entries(colors).forEach(([key, value]) => {
    // Split the color key by hyphen to separate the base name and scale
    const [baseColor, scale] = key.split("-");

    if (scale) {
      // If the scale exists, add it under the base color group
      colorScales[baseColor] = colorScales[baseColor] || {};
      colorScales[baseColor][scale] = value;
    } else {
      // If there's no scale, add it directly as a base color
      colorScales[baseColor] = colorScales[baseColor] || {};
      colorScales[baseColor].default = value;
    }
  });

  return colorScales;
}

export { transformColors, convertToColorScales };
