import { lazyLoad } from "utils";

export const CreateNewPasswordPage = lazyLoad(
  () => import("./create-new-password/index"),
  (module) => module.CreateNewPasswordPage
);

export const ForgotPasswordPage = lazyLoad(
  () => import("./forgot-password/index"),
  (module) => module.ForgotPasswordPage
);

export const LoginPage = lazyLoad(
  () => import("./login/index"),
  (module) => module.LoginPage
);

export const VerifyPasswordPage = lazyLoad(
  () => import("./verify-password/index"),
  (module) => module.VerifyPasswordPage
);
