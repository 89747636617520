import React from "react";

// import { IImageSectionWithTextProps } from "./types";
import SplitScreenImage from "assets/images/auth-split-screen-image.png";

export const ImageSectionWithText =
  (/* props: IImageSectionWithTextProps */) => {
    return (
      <div className="pl-6 2xl:pl-0 py-6">
        <div
          style={{
            background:
              "linear-gradient(148.77deg, #1C1C1C 0%, #474747 145.93%)",
          }}
          className="h-[calc(100vh-48px)] rounded-3xl relative"
        >
          <img
            className="w-full h-full rounded-3xl"
            src={SplitScreenImage}
            alt="onboarding banner"
          />
          <p className="absolute top-20 left-[8.7%] w-[77.9%] xl:w-[554px] font-semibold text-[#EBEBEB] text-[24px] leading-[38px] ">
            Manage Earnipay Apps, View Records and Ensure Smooth Operations.
          </p>
        </div>
      </div>
    );
  };
