import React, { memo } from "react";

import { useSideBar } from "contexts/sidebar-context";

import { SideBar } from "ui/sidebar";
import { TopBar } from "ui/topbar";

import { IAppLayoutProps } from "./types";

export const AppLayout = memo((props: IAppLayoutProps) => {
  const { children } = props;
  const { isSideBarCollapsed } = useSideBar();

  return (
    <div className="flex bg-[#F6F6F6]">
      <SideBar isSideBarCollapsed={isSideBarCollapsed} />
      <TopBar isSideBarCollapsed={isSideBarCollapsed} />
      <div
        className={`w-full transition-all duration-500 ease-out  ${isSideBarCollapsed ? "max-w-[calc(100%-76px)] ml-[100px]" : "max-w-[calc(100%-250px)] 2xl:max-w-[calc(100%-400px)] ml-[274px] 2xl:ml-[424px]"} mr-6 overflow-hidden min-h-screen mt-[88px]`}
      >
        {children}
      </div>
    </div>
  );
});
