import { SystemStyleObject } from "@chakra-ui/react";

export const profileDropDownMenuContentCss: SystemStyleObject = {
  width: "353px",
  height: "356px",
  borderRadius: "12px",
  backgroundColor: "#ffffff",
  boxShadow: "2px 2px 20px 0px #0000001C",
  padding: "0",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  justifyContent: "space-between",
  paddingBottom: "31px",
};
