import React from "react";

import { useSideBar } from "contexts/sidebar-context";
import { Link } from "react-router-dom";
import { formatLastPathNameIntoAReadableFormat } from "utils";

import { SearchInput } from "../search-input/SearchInput";
import { SearchResultPopUp } from "../search-result-popup/SearchResultPopUp";
import { ISideBarSearchProps } from "./types";

export const SideBarSearch = (props: ISideBarSearchProps) => {
  const {
    searchQuery,
    handleSearchQueryChange,
    searchResultPopUpRef,
    filteredRoutesList,
    handleClickFilteredRouteLink,
  } = props;
  const { isSideBarCollapsed } = useSideBar();

  return (
    <>
      {!isSideBarCollapsed && (
        <div className="px-4">
          <SearchInput
            searchQuery={searchQuery}
            handleSearchQueryChange={handleSearchQueryChange}
          />
        </div>
      )}
      <SearchResultPopUp ref={searchResultPopUpRef}>
        <div className="flex flex-col gap-4">
          <div className="px-4">
            <SearchInput
              searchQuery={searchQuery}
              handleSearchQueryChange={handleSearchQueryChange}
              className="w-[134px]"
            />
          </div>
          <div className="flex flex-col gap-4 max-h-[300px] overflow-auto px-4">
            {filteredRoutesList.map((route, index) => (
              <Link
                to={route}
                key={index}
                onClick={handleClickFilteredRouteLink}
              >
                {formatLastPathNameIntoAReadableFormat(route)}
              </Link>
            ))}
          </div>
        </div>
      </SearchResultPopUp>
    </>
  );
};
