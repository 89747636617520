import React, { memo } from "react";

import { Route, Routes } from "react-router-dom";

import { IAppRouteProps } from "./types";

export const AppRoutes = memo((props: IAppRouteProps) => {
  const { appRoutes, Layout } = props;

  return (
    <Routes>
      {appRoutes.map(({ path, element }, aridx) => (
        <React.Fragment key={aridx}>
          {path !== "*" ? (
            <Route
              path={path}
              element={Layout ? <Layout>{element}</Layout> : element}
            />
          ) : (
            <Route
              path={path}
              element={element}
            />
          )}
        </React.Fragment>
      ))}
    </Routes>
  );
});
