import React from "react";

import { IEnvironmentProps } from "./types";

export const Environment = (props: IEnvironmentProps) => {
  const { children, showOnLocal, showOnDev, showOnProd } = props;
  const url = window.location.href;
  const { hostname } = new URL(url);

  if (
    (showOnLocal &&
      hostname === "localhost" &&
      process.env.NODE_ENV === "development") ||
    ((showOnDev || showOnProd) && process.env.NODE_ENV === "production")
  ) {
    return <>{children}</>;
  }

  return <></>;
};
