import { forwardRef } from "react";

import { Button as ChakraButton, Flex } from "@chakra-ui/react";

import { TCombinedButtonProps } from "./types";

export const Button = forwardRef<HTMLButtonElement, TCombinedButtonProps>(
  (
    {
      children,
      variant = "solid",
      isRightIcon = false,
      colorPalette,
      textColor,
      bgColor,
      boxShadow,
      px,
      py,
      icon,
      loading = false,
      loadingText = "loading",
      disabled = false,
      border,
      fontSize,
      fontWeight,
      lineHeight,
      borderRadius = "8px",
      ...rest
    },
    ref
  ) => {
    const spinnerIcon = (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-spin"
      >
        <path
          d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM4.2 12C4.2 16.3078 7.69218 19.8 12 19.8C16.3078 19.8 19.8 16.3078 19.8 12C19.8 7.69218 16.3078 4.2 12 4.2C7.69218 4.2 4.2 7.69218 4.2 12Z"
          fill={variant === "solid" ? "#D9D9D9" : "#232323"}
          fillOpacity="0.2"
        />
        <path
          className="button-spinner"
          d="M12 21.9C12 23.0598 12.9473 24.0187 14.0894 23.8168C16.496 23.3912 18.7336 22.237 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.7336 1.763 16.496 0.608785 14.0894 0.183243C12.9473 -0.018698 12 0.940203 12 2.1C12 3.2598 12.9568 4.17245 14.0748 4.48099C15.3644 4.83689 16.5528 5.52193 17.5154 6.48457C18.9782 7.94735 19.8 9.93131 19.8 12C19.8 14.0687 18.9782 16.0527 17.5154 17.5154C16.5528 18.4781 15.3644 19.1631 14.0748 19.519C12.9568 19.8275 12 20.7402 12 21.9Z"
          fill={variant === "solid" ? "#ffffff" : "#000000"}
        />
      </svg>
    );

    // Determine the content of the button based on the loading state
    let content;
    if (loading) {
      content = (
        <Flex
          alignItems={"center"}
          gap={2}
        >
          <span>{spinnerIcon}</span>
          <span>{loadingText || children}</span>
        </Flex>
      );
    } else {
      content = children;
    }

    return (
      <ChakraButton
        disabled={loading || disabled}
        color={
          variant === "solid" ? textColor || "white" : textColor || "#101218"
        }
        bgColor={variant === "solid" ? bgColor || "ep" : "transparent"}
        border={variant === "outline" ? border || "1px solid #101218" : "none"}
        borderRadius={borderRadius}
        px={px || "4.5"}
        py={py || "2.5"}
        ref={ref}
        variant={variant}
        colorPalette={colorPalette || "ep"}
        fontWeight={fontWeight || "medium"}
        fontSize={fontSize || "14px"}
        lineHeight={lineHeight || "24px"}
        boxShadow={
          variant === "solid"
            ? boxShadow || "0px 1px 2px 0px #1018280D"
            : boxShadow || "none"
        }
        _hover={
          variant === "solid"
            ? {
                bgColor: bgColor || "ep-600",
                color: textColor || "white",
              }
            : {
                bgColor: "transparent",
                color: textColor || "#101218",
              }
        }
        css={{
          "&:disabled": {
            cursor: "not-allowed",
          },
        }}
        {...rest}
      >
        {!isRightIcon && icon}
        {content}
        {isRightIcon && icon}
      </ChakraButton>
    );
  }
);
