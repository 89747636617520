export enum ROUTES_BASE {
  DASHBOARD = "/dashboard",
  COMPLIANCE = "/compliance",
  BUSINESSES = "/businesses",
  INDIVIDUALS = "/individuals",
  TRANSACTIONS = "/transactions",
  CREDIT = "/credit",
  REVENUE = "/revenue",
  SAVINGS = "/savings",
  REWARDS = "/rewards",
  REFERRAL_PROGRAM = "/referral-program",
  MARKETING = "/marketing",
  ON_DEMAND_SALARY = "/on-demand-salary",
  SUPPORT = "/support",
  SETTINGS = "/settings",
  AUDIT_LOGS = "/audit-logs",
  AUTH = "/auth",
  UI_COMPONENTS_DEMO = "/ui-components-demo",
  LANDING = "/",
}

export enum ROUTES {
  DASHBOARD_PAGE = ROUTES_BASE.DASHBOARD,
  COMPLIANCE_PAGE = ROUTES_BASE.COMPLIANCE,
  BUSINESSES_COMPLIANCE_PAGE = `${ROUTES_BASE.COMPLIANCE}/businesses`,
  BUSINESSES_COMPLIANCE_PAGE_READY_FOR_REVIEW = `${ROUTES_BASE.COMPLIANCE}/businesses/ready-for-review`,
  BUSINESSES_COMPLIANCE_PAGE_REJECTED = `${ROUTES_BASE.COMPLIANCE}/businesses/rejected`,
  INDIVIDUALS_COMPLIANCE_PAGE = `${ROUTES_BASE.COMPLIANCE}/individuals`,
  BUSINESSES_PAGE = ROUTES_BASE.BUSINESSES,
  INDIVIDUALS_PAGE = ROUTES_BASE.INDIVIDUALS,
  TRANSACTIONS_PAGE = ROUTES_BASE.TRANSACTIONS,
  CREDIT_PAGE = ROUTES_BASE.CREDIT,
  REVENUE_PAGE = ROUTES_BASE.REVENUE,
  SAVINGS_PAGE = ROUTES_BASE.SAVINGS,
  REWARDS_PAGE = ROUTES_BASE.REWARDS,
  REFERRAL_PROGRAM_PAGE = ROUTES_BASE.REFERRAL_PROGRAM,
  MARKETING_PAGE = ROUTES_BASE.MARKETING,
  ON_DEMAND_SALARY_PAGE = ROUTES_BASE.ON_DEMAND_SALARY,
  SUPPORT_PAGE = ROUTES_BASE.SUPPORT,
  SETTINGS_PAGE = ROUTES_BASE.SETTINGS,
  AUDIT_LOGS_PAGE = ROUTES_BASE.AUDIT_LOGS,
  CREATE_PASSWORD_PAGE = `${ROUTES_BASE.AUTH}/create-new-password`,
  FORGOT_PASSWORD_PAGE = `${ROUTES_BASE.AUTH}/forgot-password`,
  LOGIN_PAGE = `${ROUTES_BASE.AUTH}/login`,
  VERIFY_PASSWORD_PAGE = `${ROUTES_BASE.AUTH}/verify-password`,
  UI_COMPONENTS_DEMO_PAGE = ROUTES_BASE.UI_COMPONENTS_DEMO,
  UI_COMPONENTS_DEMO_APPROVED_PAGE = `${ROUTES.UI_COMPONENTS_DEMO_PAGE}/approved`,
  UI_COMPONENTS_DEMO_REJECTED_PAGE = `${ROUTES.UI_COMPONENTS_DEMO_PAGE}/rejected`,
  LANDING_PAGE = ROUTES_BASE.LANDING,
}
