import React, { memo, useEffect, useRef, useState } from "react";

import { ROUTES } from "routes/constants";

import { ISearchResultPopUpRef } from "./search-result-popup/types";
import { SideBarHeader } from "./sidebar-header";
import { SideBarRoutes } from "./sidebar-routes";
import { SideBarSearch } from "./sidebar-search";
import { ISideBarProps } from "./types";

export const EMPTY_LIST_LENGTH = 0;
export const SideBar = memo((props: ISideBarProps) => {
  const { isSideBarCollapsed } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const searchResultPopUpRef = useRef<ISearchResultPopUpRef>(null);
  const [filteredRoutesList, setFilteredRoutesList] = useState<string[]>([]);

  const routesList = Object.values(ROUTES) as string[];
  const handleSearchRoutesList = (searchTerm: string): string[] => {
    return routesList.filter((route) => route.includes(searchTerm));
  };

  const handleSearchQueryChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = evt.target;
    const routesFound = handleSearchRoutesList(value);
    setFilteredRoutesList(routesFound);
    setSearchQuery(value);
  };

  const handleClickFilteredRouteLink = () => {
    if (!searchResultPopUpRef.current) return;
    searchResultPopUpRef.current.handleTogglePopup();
    setSearchQuery("");
  };

  useEffect(() => {
    if (
      searchQuery.length > EMPTY_LIST_LENGTH &&
      searchResultPopUpRef.current &&
      !searchResultPopUpRef.current.isPopUpOpen
    ) {
      searchResultPopUpRef.current.handleTogglePopup();
    }
    /* THIS HERE WILL CLOSE THE POPUP WHEN THE INPUT IS EMPTY */
    /* else if (
			searchQuery.length === 0 &&
			searchResultPopUpRef.current &&
			searchResultPopUpRef.current.isPopUpOpen
		) {
			searchResultPopUpRef.current.handleTogglePopup();
		} */
  }, [searchQuery]);

  return (
    <div
      className={`w-full ${isSideBarCollapsed ? "max-w-[76px]" : "max-w-[250px] 2xl:max-w-[400px]"} flex flex-shrink-0 bg-ep-black text-white overflow-hidden h-screen fixed transition-all duration-500 ease-out`} /*  */
    >
      <div className="flex flex-col gap-6 w-full py-6">
        <SideBarHeader />
        <SideBarSearch
          searchQuery={searchQuery}
          handleSearchQueryChange={handleSearchQueryChange}
          searchResultPopUpRef={searchResultPopUpRef}
          filteredRoutesList={filteredRoutesList}
          handleClickFilteredRouteLink={handleClickFilteredRouteLink}
        />
        <SideBarRoutes />
      </div>
    </div>
  );
});
