import { IUserData } from "api/auth/types";

const AUTH_TOKEN_NAME_KEY = "AUTH_TOKEN_NAME_KEY";
const USER_DATA_NAME_KEY = "USER_DATA_NAME_KEY";

export const storage = {
  get authToken(): string | null {
    try {
      return localStorage.getItem(AUTH_TOKEN_NAME_KEY) || null;
    } catch (error) {
      console.error("Error accessing authToken:", error);
      return null;
    }
  },
  set authToken(token: string | null) {
    try {
      if (token) {
        localStorage.setItem(AUTH_TOKEN_NAME_KEY, token);
      } else {
        localStorage.removeItem(AUTH_TOKEN_NAME_KEY);
      }
    } catch (error) {
      console.error("Error setting authToken:", error);
    }
  },

  get userData(): IUserData | null {
    try {
      const data = localStorage.getItem(USER_DATA_NAME_KEY);
      return data ? (JSON.parse(data) as IUserData) : null;
    } catch (error) {
      console.error("Error accessing userData:", error);
      return null;
    }
  },
  set userData(userData: IUserData | null) {
    try {
      if (userData) {
        localStorage.setItem(USER_DATA_NAME_KEY, JSON.stringify(userData));
      } else {
        localStorage.removeItem(USER_DATA_NAME_KEY);
      }
    } catch (error) {
      console.error("Error setting userData:", error);
    }
  },
};
