import { defineLayerStyles } from "@chakra-ui/react";

// import { convertToColorScales } from "utils/transform";
// import { epColor } from "./constants";

// const colors = transformColors(epColor);
// const colors = convertToColorScales(epColor);

export const layerStyles = defineLayerStyles({
  //   fill: {
  //     solid: {
  //       description: "",
  //       value: {
  //         bg: colors.ep["500"],
  //         border: "2px solid",
  //         // bg: "gray.50",
  //         // borderColor: "gray.500",
  //       },
  //     },
  //   },
});
