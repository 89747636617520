import React from "react";

import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron-down.svg";
import { useSideBar } from "contexts/sidebar-context";
import { NavLink, useLocation } from "react-router-dom";
import { truncateText } from "utils/general";

import { Paragraph2 } from "ui/typography";

import { MAX_LINK_TEXT_LENGTH, SIDE_BAR_ROUTES } from "../consts";
// import { ISideBarRoutesProps } from "./types";
import { RouteIconAndText } from "./RouteIconAndText";

export const SideBarRoutes = (/* props: ISideBarRoutesProps */) => {
  const location = useLocation();
  const {
    handleCloseAllOpenSubRoutes,
    isSideBarCollapsed,
    handleToggleSubRoutes,
    expandedRoutes,
  } = useSideBar();

  const handleRouteClick = (
    path: string,
    isSubRoutesTitleNotARoute?: boolean,
    hasSubRoutes?: boolean
  ) => {
    if (isSubRoutesTitleNotARoute) {
      return handleToggleSubRoutes(path);
    } else if (!hasSubRoutes) {
      return handleCloseAllOpenSubRoutes();
    }
    return undefined;
  };

  return (
    <div className="sidebar-inners flex-grow transition-all duration-500 ease-out">
      <div className="flex flex-col gap-2 px-4">
        {SIDE_BAR_ROUTES.map(
          (
            {
              icon,
              route: { path, text },
              subRoutes,
              hasSubRoutes,
              isSubRoutesTitleNotARoute,
            },
            index
          ) => (
            <div key={index}>
              <div
                className={`flex items-center w-full h-10 py-[10px] px-3 justify-between ${location.pathname === path ? "active-sidebar-menu-option" : "in-active-sidebar-menu-option"} ${isSubRoutesTitleNotARoute ? "cursor-pointer" : ""}`}
                onClick={() =>
                  handleRouteClick(
                    path,
                    isSubRoutesTitleNotARoute,
                    hasSubRoutes
                  )
                }
              >
                {isSubRoutesTitleNotARoute ? (
                  <RouteIconAndText
                    icon={icon}
                    isSideBarCollapsed={isSideBarCollapsed}
                    text={text}
                  />
                ) : (
                  <NavLink
                    to={path}
                    className="flex items-center cursor-pointer w-full"
                    title={text}
                  >
                    <RouteIconAndText
                      icon={icon}
                      isSideBarCollapsed={isSideBarCollapsed}
                      text={text}
                    />
                  </NavLink>
                )}
                {hasSubRoutes && !isSideBarCollapsed && (
                  <div
                    className="cursor-pointer"
                    onClick={() => handleToggleSubRoutes(path)}
                  >
                    <ChevronDownIcon
                      className={`${expandedRoutes[path] ? "rotate-180" : ""}`}
                    />
                  </div>
                )}
              </div>
              {hasSubRoutes && expandedRoutes[path] && (
                <div className="flex flex-col gap-2 mt-2">
                  {subRoutes &&
                    subRoutes.map((subRoute, subIndex) => (
                      <NavLink
                        key={subIndex}
                        to={subRoute.path}
                        className={`flex items-center pl-10 h-10 ${location.pathname === subRoute.path ? "active-sidebar-menu-option" : "in-active-sidebar-menu-option"}`}
                        title={text}
                      >
                        <Paragraph2
                          lineHeight="18px"
                          textColor="#ffffff"
                        >
                          {truncateText(subRoute.text, MAX_LINK_TEXT_LENGTH)}
                        </Paragraph2>
                      </NavLink>
                    ))}
                </div>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};
