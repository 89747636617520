export const SearchResultPopUpCss = {
  width: "218px",
  backgroundColor: "#232529",
  padding: "16px 0 24px",
  borderRadius: "12px",
  boxShadow: "0px 8px 8px -4px #10182808",
  color: "#F9FAFB",
  margin: "75px 16px 0",
  minHeight: "188px",
  maxHeight: "400px",
};
