import { toast } from "react-toastify";

import { CustomToast } from "./CustomToast";
import { ICustomToastAction, TCustomToastOptions } from "./types";

const DEFAULT_AUTO_CLOSE_TIME = 3000;
const defaultToastOption: TCustomToastOptions = {
  autoClose: DEFAULT_AUTO_CLOSE_TIME,
  hideProgressBar: true,
  closeOnClick: false,
};

export const customErrorToast = (
  { message, title, isLoading }: ICustomToastAction,
  customToastOption?: TCustomToastOptions
) => {
  const toastOption = customToastOption ? customToastOption : {};
  toast(
    ({ closeToast }) => (
      <CustomToast
        type="error"
        message={message}
        closeToast={closeToast}
        title={title}
        isLoading={isLoading}
      />
    ),
    { ...defaultToastOption, ...toastOption }
  );
};

export const customSuccessToast = (
  { message, title, isLoading }: ICustomToastAction,
  customToastOption?: TCustomToastOptions
) => {
  const toastOption = customToastOption ? customToastOption : {};
  toast(
    ({ closeToast }) => (
      <CustomToast
        type="success"
        message={message}
        closeToast={closeToast}
        title={title}
        isLoading={isLoading}
      />
    ),
    { ...defaultToastOption, ...toastOption }
  );
};

export const customWarningToast = (
  { message, title, isLoading }: ICustomToastAction,
  customToastOption?: TCustomToastOptions
) => {
  const toastOption = customToastOption ? customToastOption : {};
  toast(
    ({ closeToast }) => (
      <CustomToast
        type="warning"
        message={message}
        closeToast={closeToast}
        title={title}
        isLoading={isLoading}
      />
    ),
    { ...defaultToastOption, ...toastOption }
  );
};

export const customInfoToast = (
  { message, title, isLoading }: ICustomToastAction,
  customToastOption?: TCustomToastOptions
) => {
  const toastOption = customToastOption ? customToastOption : {};
  toast(
    ({ closeToast }) => (
      <CustomToast
        type="info"
        message={message}
        closeToast={closeToast}
        title={title}
        isLoading={isLoading}
      />
    ),
    { ...defaultToastOption, ...toastOption }
  );
};
