import React from "react";

import { Box } from "@chakra-ui/react";
import { ReactComponent as ChevronRightIcon } from "assets/icons/chevron-right-dark-grey.svg";
import { capitalizeWords } from "utils";
import { START_INDEX } from "utils/constants";

import { Paragraph2, Paragraph3 } from "ui/typography";

import { IProfileDropDownContentProps } from "./types";

export const ProfileDropDownContent = (props: IProfileDropDownContentProps) => {
  const { userData, handleLogOut } = props;

  return (
    <>
      <Box className="h-[215px] flex-shrink-0">
        <Box className="w-full h-[75px] bg-[#EBF1F2]" />
        <Box className="h-[164px] translate-y-[-24px] flex flex-col items-center gap-3">
          <Box className="bg-[#027BE5] w-full max-w-20 basis-20 flex-shrink-0 h-20 rounded-[50%] font-medium text-[32px] leading-[48px] flex items-center justify-center text-white">
            {userData.firstName.charAt(START_INDEX)}
          </Box>
          <Box className="h-[72px] flex flex-col items-center gap-3">
            <Box className="h-[38px] flex flex-col items-center gap-2">
              <Paragraph2
                lineHeight="16px"
                fontWeight="500"
                textColor="#1A202C"
                className="text-center h-4"
              >
                {userData.firstName}&nbsp;{userData.lastName}
              </Paragraph2>
              <Paragraph3
                lineHeight="14px"
                textColor="#718096"
                className="text-center h-[14px]"
              >
                {userData.email}
              </Paragraph3>
            </Box>
            <Box className="w-[87px] h-[22px] rounded-2xl py-[2px] px-2 bg-[#FFF6ED] text-[#C4320A] font-medium text-xs leading-[18px] text-center whitespace-nowrap">
              {capitalizeWords(userData.userType.replaceAll("-", " "))}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="bg-[#F3F4F6] h-[1px] flex-shrink-0" />
      <Box className="h-[62px] flex flex-col gap-6 flex-shrink-0">
        <Box className="flex justify-between items-center h-5 py-[2px] px-6 cursor-pointer">
          <Paragraph2
            lineHeight="14px"
            fontWeight="500"
            textColor="#4A5564"
          >
            Manage Account
          </Paragraph2>
          <ChevronRightIcon />
        </Box>
        <Paragraph2
          lineHeight="14px"
          fontWeight="500"
          textColor="#EF4444"
          className="cursor-pointer h-[18px] py-[2px] px-6 w-fit"
          onClick={handleLogOut}
        >
          Logout
        </Paragraph2>
      </Box>
    </>
  );
};
