import React, { memo } from "react";

import { AuthGuard } from "components/auth-guard";
import { AppRoutes } from "components/common/app-routes";
import { ScrollToTop } from "components/common/scroll-to-top";
import { NoAuthGuard } from "components/no-auth-guard";
import { NotFoundContainer } from "components/not-found-container";
import { AppLayout } from "layouts/app-layout";
import { AuthLayout } from "layouts/auth-layout";
import { Route, Routes } from "react-router-dom";

import { Heading1 } from "ui/typography";

import { ROUTES_BASE } from "./constants";
import {
  AUTH_ROUTES,
  COMPLIANCE_ROUTES, // DASHBOARD_ROUTES,
  UICOMPONENTS_DEMO_ROUTES,
} from "./routes";

export const MainRoutes = memo(() => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        {/* <Route
          path={`${ROUTES_BASE.DASHBOARD}/*`}
          element={
            <AuthGuard>
              <AppRoutes
                appRouteName="Dashboard"
                appRoutes={DASHBOARD_ROUTES}
                Layout={AppLayout}
              />
            </AuthGuard>
          }
        /> */}
        <Route
          path={`${ROUTES_BASE.AUTH}/*`}
          element={
            <NoAuthGuard>
              <AppRoutes
                appRouteName="Authentication"
                appRoutes={AUTH_ROUTES}
                Layout={AuthLayout}
              />
            </NoAuthGuard>
          }
        />
        <Route
          path={`${ROUTES_BASE.UI_COMPONENTS_DEMO}/*`}
          element={
            <AuthGuard>
              <AppRoutes
                appRouteName="UI Components Demo"
                appRoutes={UICOMPONENTS_DEMO_ROUTES}
                Layout={AppLayout}
              />
            </AuthGuard>
          }
        />
        <Route
          path={`${ROUTES_BASE.COMPLIANCE}/*`}
          element={
            <AuthGuard>
              <AppRoutes
                appRouteName="Compliance"
                appRoutes={COMPLIANCE_ROUTES}
                Layout={AppLayout}
              />
            </AuthGuard>
          }
        />
        <Route
          path={`${ROUTES_BASE.LANDING}`}
          element={
            <AuthGuard>
              <AppLayout>
                <Heading1>LANDING PAGE</Heading1>
              </AppLayout>
            </AuthGuard>
          }
        />
        {/* General 404 page */}
        <Route
          path="*"
          element={<NotFoundContainer />}
        />
      </Routes>
    </>
  );
});
