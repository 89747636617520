import React, { memo } from "react";

import { IAppRoute } from "components/common/app-routes/types";
import { NotFoundContainer } from "components/not-found-container";
import {
  CreateNewPasswordPage,
  ForgotPasswordPage,
  LoginPage,
  VerifyPasswordPage,
} from "pages/auth/Loadable";
import { BusinessCompliancePageLazy } from "pages/business-compliance/Loadable";
// import { DashboardPage } from "pages/dashboard/Loadable";
import { UiComponentsDemoPage } from "pages/ui-components-demo/Loadable";

import { ROUTES, ROUTES_BASE } from "./constants";

const removeBasePathFromRoute = (
  basePath: ROUTES_BASE,
  route: string
): string => {
  return route.replace(basePath, "");
};

export const AUTH_ROUTES: IAppRoute[] = [
  {
    path: removeBasePathFromRoute(
      ROUTES_BASE.AUTH,
      ROUTES.CREATE_PASSWORD_PAGE
    ),
    element: <CreateNewPasswordPage />,
  },
  {
    path: removeBasePathFromRoute(
      ROUTES_BASE.AUTH,
      ROUTES.FORGOT_PASSWORD_PAGE
    ),
    element: <ForgotPasswordPage />,
  },
  {
    path: removeBasePathFromRoute(
      ROUTES_BASE.AUTH,
      ROUTES.VERIFY_PASSWORD_PAGE
    ),
    element: <VerifyPasswordPage />,
  },
  {
    path: removeBasePathFromRoute(ROUTES_BASE.AUTH, ROUTES.LOGIN_PAGE),
    element: <LoginPage />,
  },
  {
    path: "*",
    element: <NotFoundContainer />,
  },
];

// export const DASHBOARD_ROUTES: IAppRoute[] = [
//   {
//     path: removeBasePathFromRoute(ROUTES_BASE.DASHBOARD, ROUTES.DASHBOARD_PAGE),
//     element: <DashboardPage />,
//   },
// ];

export const UICOMPONENTS_DEMO_ROUTES: IAppRoute[] = [
  {
    path: removeBasePathFromRoute(
      ROUTES_BASE.UI_COMPONENTS_DEMO,
      ROUTES.UI_COMPONENTS_DEMO_PAGE
    ),
    element: <UiComponentsDemoPage />,
  },
  {
    path: removeBasePathFromRoute(
      ROUTES_BASE.UI_COMPONENTS_DEMO,
      ROUTES.UI_COMPONENTS_DEMO_APPROVED_PAGE
    ),
    element: <UiComponentsDemoPage />,
  },
  {
    path: removeBasePathFromRoute(
      ROUTES_BASE.UI_COMPONENTS_DEMO,
      ROUTES.UI_COMPONENTS_DEMO_REJECTED_PAGE
    ),
    element: <UiComponentsDemoPage />,
  },
];

const BusinessCompliancePageRoute = memo(() => <BusinessCompliancePageLazy />);
export const COMPLIANCE_ROUTES: IAppRoute[] = [
  // {
  //   path: removeBasePathFromRoute(
  //     ROUTES_BASE.COMPLIANCE,
  //     ROUTES.BUSINESSES_COMPLIANCE_PAGE
  //   ),
  //   element: <BusinessCompliancePageLazy />,
  // },
  {
    path: removeBasePathFromRoute(
      ROUTES_BASE.COMPLIANCE,
      ROUTES.BUSINESSES_COMPLIANCE_PAGE_READY_FOR_REVIEW
    ),
    element: <BusinessCompliancePageRoute />,
  },
  {
    path: removeBasePathFromRoute(
      ROUTES_BASE.COMPLIANCE,
      ROUTES.BUSINESSES_COMPLIANCE_PAGE_REJECTED
    ),
    element: <BusinessCompliancePageRoute />,
  },
  {
    path: "*",
    element: <NotFoundContainer />,
  },
];
