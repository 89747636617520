import React, { forwardRef, useImperativeHandle, useState } from "react";

import { MenuContent, MenuRoot } from "components/ui/menu";

import { SearchResultPopUpCss } from "./styles";
import { ISearchResultPopUpRef, ISearchResultProp } from "./types";

export const SearchResultPopUp = forwardRef<
  ISearchResultPopUpRef,
  ISearchResultProp
>((props, ref) => {
  const { children } = props;
  const [open, setOpen] = useState(false);

  const handleToggle = () => setOpen(!open);
  const handleClose = () => setOpen(false);

  useImperativeHandle(ref, () => ({
    isPopUpOpen: open,
    handleTogglePopup: handleToggle,
  }));

  return (
    <MenuRoot
      open={open}
      onInteractOutside={handleClose}
    >
      <MenuContent css={SearchResultPopUpCss}>{children}</MenuContent>
    </MenuRoot>
  );
});
