import React from "react";

import { ReactComponent as SearchIcon } from "assets/icons/search-normal.svg";

import { ISearchInputProps } from "./types";

export const SearchInput = (props: ISearchInputProps) => {
  const { searchQuery, handleSearchQueryChange, className } = props;
  return (
    <div className="bg-[#FFFFFF1A] h-10 py-[10px] px-3 flex items-center gap-2 rounded-lg relative">
      <div>
        <SearchIcon />
      </div>
      <input
        placeholder="Find a module..."
        className={`text-white absolute bg-transparent w-[166px] h-5 left-10 placeholder:text-[#9F9F9F] focus:outline-none ${className}`}
        onChange={handleSearchQueryChange}
        value={searchQuery}
      />
    </div>
  );
};
