import { createSystem, defaultConfig, defineConfig } from "@chakra-ui/react";
import { transformColors } from "utils/transform";

import { epColor } from "./constants";
// import { buttonRecipe } from "./recipes";
import { layerStyles } from "./layer-styles";

// import { CardRecipe } from "./recipes/cardRecipes";
// import { buttonTheme as Button } from "./theme-components"

const customConfig = defineConfig({
  globalCss: {
    "html, body": {
      margin: 0,
      padding: 0,
    },
    "*::placeholder": {
      opacity: 1,
      color: "#718096",
    },
  },
  theme: {
    tokens: {
      fonts: {
        heading: { value: `'Manrope', sans-serif` },
        body: { value: `'Manrope', sans-serif` },
      },
      colors: transformColors(epColor),
      // colors: convertToColorScales(epColor),
    },
    semanticTokens: {
      colors: {
        ep: {
          solid: {
            value: "{colors.ep-500}",
          },
          contrast: {
            value: "#ffffff",
          },
          fg: {
            value: "#ffffff",
          },
          focusRing: {
            value: { base: "{colors.ep}", _dark: "{colors.ep}" },
          },
        },
      },
    },
    keyframes: {
      spin: {
        from: { transform: "rotate(0deg)" },
        to: { transform: "rotate(360deg)" },
      },
      ping: {
        "75%, 100%": {
          transform: "scale(2)",
          opacity: "0",
        },
      },
      pulse: {
        "0%, 100%": { opacity: "1" },
        "50%": { opacity: ".5" },
      },
      bounce: {
        "0%, 100%": {
          transform: "translateY(-25%)",
          animationTimingFunction: "cubic-bezier(0.8, 0, 1, 1)",
        },
        "50%": {
          transform: "translateY(0)",
          animationTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        },
      },
    },
    recipes: {
      // button: Button,
      // card: CardRecipe,
    },
    layerStyles,
  },
});

export const system = createSystem(defaultConfig, customConfig);
