import React from "react";

import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "api/react-query/queryClient";
import { Provider as ChakraProvider } from "components/ui/provider";
import { AuthProvider } from "contexts/auth-context";
import { SideBarProvider } from "contexts/sidebar-context";
import ReactDOM from "react-dom/client";
import "react-photo-view/dist/react-photo-view.css";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./css/customToast.css";
import "./css/index.css";

import { App } from "./app";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <Router>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <SideBarProvider>
              <App />
              <ToastContainer
                position="top-right"
                hideProgressBar={false}
                closeButton={false}
                pauseOnHover
                closeOnClick
                draggable
              />
            </SideBarProvider>
          </QueryClientProvider>
        </AuthProvider>
      </Router>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
