import React, { useMemo } from "react";

import { Link, useLocation } from "react-router-dom";
import { formatPathNameIntoReadableFormat } from "utils";

import { Paragraph3 } from "ui/typography";

import { IBreadCrumbNavProps } from "./types";

const SLICE_START_INDEX = 1;
const START_INDEX = 0;
const CHARACTER_POSITION_INDEX = START_INDEX;
const INDEX_OFFSET = 1;
const MAX_VISIBLE_BREAD_CRUMB_LINK = 3;

export const BreadCrumbNav = (props: IBreadCrumbNavProps) => {
  const {
    isSideBarCollapsed,
    isTabDevice,
    isMobileDevice,
    isMobileMdDevice,
    isMobileSmDevice,
  } = props;
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter((pathName) => pathName);

  const breadCrumbNavResponsiveClassNames: string = useMemo(() => {
    let classNames = "";
    if (
      (isMobileMdDevice && !isSideBarCollapsed) ||
      (isMobileSmDevice && isSideBarCollapsed)
    ) {
      classNames = "max-w-full";
    } else if (
      (isTabDevice && !isSideBarCollapsed) ||
      (isMobileDevice && isSideBarCollapsed)
    ) {
      classNames = "max-w-[calc(100%-150px)]";
    }

    return classNames;
  }, [
    isMobileMdDevice,
    isSideBarCollapsed,
    isMobileSmDevice,
    isTabDevice,
    isMobileDevice,
  ]);

  if (pathNames.length === START_INDEX) {
    return (
      <ol
        className={`flex list-none h-full w-full ${breadCrumbNavResponsiveClassNames} max-w-[30%] xlg:max-w-[25.5%] overflow-hidden xlg:overflow-auto`}
      >
        <li className="flex items-center flex-[1] whitespace-nowrap overflow-hidden text-ellipsis">
          <Link
            to="/"
            className="font-medium text-[18px] text-[#374151] max-w-[113px] overflow-hidden whitespace-nowrap text-ellipsis"
          >
            Landing
          </Link>
        </li>
      </ol>
    );
  }

  return (
    <ol
      className={`flex list-none h-full w-full ${breadCrumbNavResponsiveClassNames} max-w-[30%] xlg:max-w-[25.5%] overflow-hidden xlg:overflow-auto`}
    >
      {pathNames.map((pathName, index) => {
        const to = `/${pathNames.slice(START_INDEX, index + INDEX_OFFSET).join("/")}`;
        const isLast = index === pathNames.length - INDEX_OFFSET;
        const pathNameFormatted = formatPathNameIntoReadableFormat(pathName);

        return (
          <li
            key={index}
            className={`flex items-center ${pathNames.length - INDEX_OFFSET === index ? "flex-[1] whitespace-nowrap overflow-hidden text-ellipsis" : ""}`}
          >
            {!isLast || pathNames.length < MAX_VISIBLE_BREAD_CRUMB_LINK ? (
              <>
                <Link
                  to={to}
                  className={`${index === START_INDEX ? "font-medium text-[18px] text-[#374151]" : "font-normal text-[14px] text-[#1A202C]"} max-w-[113px] overflow-hidden whitespace-nowrap text-ellipsis`}
                >
                  {pathNameFormatted}
                </Link>
                {pathNames.length - INDEX_OFFSET > index && (
                  <p
                    className={`${index === START_INDEX ? "text-black text-[16px] leading-[18.24px]" : "text-[#9CA3AF] text-[14px] leading-[15.96px]"} mx-1`}
                  >
                    /
                  </p>
                )}
              </>
            ) : (
              <Paragraph3
                textColor="#9CA3AF"
                className="max-w-[95px] overflow-hidden whitespace-nowrap text-ellipsis"
              >
                {pathNameFormatted
                  .toLowerCase()
                  .charAt(CHARACTER_POSITION_INDEX)
                  .toUpperCase() +
                  pathNameFormatted.toLowerCase().slice(SLICE_START_INDEX)}
              </Paragraph3>
            )}
          </li>
        );
      })}
    </ol>
  );
};
