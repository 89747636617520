import React from "react";

import { AuthGuard } from "components/auth-guard";
import { NoAuthGuard } from "components/no-auth-guard";
import { useAuth } from "contexts/auth-context";
import { AppLayout } from "layouts/app-layout";
import { AuthLayout } from "layouts/auth-layout";

import { Heading2, Paragraph2 } from "ui/typography";

export const NotFoundContainer = () => {
  const { isAuthenticated } = useAuth();
  return (
    <>
      {isAuthenticated ? (
        <AuthGuard>
          <AppLayout>
            <Heading2>Not Found Page</Heading2>
            <Paragraph2>
              The page you are looking for does not exist.
            </Paragraph2>
          </AppLayout>
        </AuthGuard>
      ) : (
        <NoAuthGuard>
          <AuthLayout>
            <Heading2>Not Found Page</Heading2>
            <Paragraph2>
              The page you are looking for does not exist.
            </Paragraph2>
          </AuthLayout>
        </NoAuthGuard>
      )}
    </>
  );
};
