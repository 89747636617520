import { ReactComponent as BusinessesIcon } from "assets/icons/shop.svg";
import { ReactComponent as RevenueIcon } from "assets/icons/sidebar/arrow-down.svg";
import { ReactComponent as RewardsIcon } from "assets/icons/sidebar/coin.svg";
import { ReactComponent as CreditIcon } from "assets/icons/sidebar/credit.svg";
import { ReactComponent as GridIcon } from "assets/icons/sidebar/grid.svg";
import { ReactComponent as AuditLogsIcon } from "assets/icons/sidebar/layer.svg";
import { ReactComponent as MarketingIcon } from "assets/icons/sidebar/megaphone.svg";
import { ReactComponent as SupportIcon } from "assets/icons/sidebar/message-question.svg";
import { ReactComponent as SavingsIcon } from "assets/icons/sidebar/money-bag.svg";
import { ReactComponent as OnDemandSalaryIcon } from "assets/icons/sidebar/moneys.svg";
import { ReactComponent as ReferralProgramIcon } from "assets/icons/sidebar/people.svg";
import { ReactComponent as TransactionsIcon } from "assets/icons/sidebar/reverse.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/sidebar/setting.svg";
import { ReactComponent as ShieldIcon } from "assets/icons/sidebar/shield-tick.svg";
import { ReactComponent as IndividualsIcon } from "assets/icons/sidebar/user.svg";
import { ROUTES } from "routes/constants";

import { ISideBarRoute } from "./types";

export const MAX_LINK_TEXT_LENGTH = 16;
export const SIDE_BAR_ROUTES: ISideBarRoute[] = [
  {
    route: {
      path: ROUTES.DASHBOARD_PAGE,
      text: "Dashboard",
    },
    icon: <GridIcon />,
  },
  {
    route: {
      path: ROUTES.UI_COMPONENTS_DEMO_PAGE,
      text: "UI Components Demo",
    },
    icon: <ReferralProgramIcon />,
    subRoutes: [
      {
        path: ROUTES.LOGIN_PAGE,
        text: "Auth Layout",
      },
    ],
    hasSubRoutes: true,
  },
  {
    route: {
      path: ROUTES.COMPLIANCE_PAGE,
      text: "Compliance",
    },
    icon: <ShieldIcon />,
    subRoutes: [
      {
        path: ROUTES.BUSINESSES_COMPLIANCE_PAGE_READY_FOR_REVIEW,
        text: "Businesses",
      },
      {
        path: ROUTES.INDIVIDUALS_COMPLIANCE_PAGE,
        text: "Individuals",
      },
    ],
    hasSubRoutes: true,
    isSubRoutesTitleNotARoute: true,
  },
  {
    route: {
      path: ROUTES.BUSINESSES_PAGE,
      text: "Businesses",
    },
    icon: <BusinessesIcon />,
  },
  {
    route: {
      path: ROUTES.INDIVIDUALS_PAGE,
      text: "Individuals",
    },
    icon: <IndividualsIcon />,
  },
  {
    route: {
      path: ROUTES.TRANSACTIONS_PAGE,
      text: "Transactions",
    },
    icon: <TransactionsIcon />,
  },
  {
    route: {
      path: ROUTES.CREDIT_PAGE,
      text: "Credit",
    },
    icon: <CreditIcon />,
  },
  {
    route: {
      path: ROUTES.REVENUE_PAGE,
      text: "Revenue",
    },
    icon: <RevenueIcon />,
  },
  {
    route: {
      path: ROUTES.SAVINGS_PAGE,
      text: "Savings",
    },
    icon: <SavingsIcon />,
  },
  {
    route: {
      path: ROUTES.REWARDS_PAGE,
      text: "Rewards",
    },
    icon: <RewardsIcon />,
  },
  {
    route: {
      path: ROUTES.REFERRAL_PROGRAM_PAGE,
      text: "Referral Program",
    },
    icon: <ReferralProgramIcon />,
  },
  {
    route: {
      path: ROUTES.MARKETING_PAGE,
      text: "Marketing",
    },
    icon: <MarketingIcon />,
  },
  {
    route: {
      path: ROUTES.ON_DEMAND_SALARY_PAGE,
      text: "On-Demand Salary",
    },
    icon: <OnDemandSalaryIcon />,
  },
  {
    route: {
      path: ROUTES.SUPPORT_PAGE,
      text: "Support",
    },
    icon: <SupportIcon />,
  },
  {
    route: {
      path: ROUTES.SETTINGS_PAGE,
      text: "Settings",
    },
    icon: <SettingsIcon />,
  },
  {
    route: {
      path: ROUTES.AUDIT_LOGS_PAGE,
      text: "Audit Logs",
    },
    icon: <AuditLogsIcon />,
  },
];
