import { DialogContent, DialogRoot } from "components/ui/dialog";

import { IModalProps } from "./types";

export const Modal = (props: IModalProps) => {
  const {
    isOpen,
    setIsOpen,
    children,
    allowOutsideClick,
    size,
    modalCss,
    position,
  } = props;

  const centerCss = {
    marginTop: position && position === "center" ? "auto" : "",
    marginBottom: position && position === "center" ? "auto" : "",
  };

  const dialogsContentCss = {
    ...modalCss,
    ...centerCss,
  };

  return (
    <DialogRoot
      lazyMount
      open={isOpen}
      onOpenChange={(evt) => setIsOpen(evt.open)}
      closeOnInteractOutside={allowOutsideClick ?? true}
      size={size}
    >
      <DialogContent css={dialogsContentCss}>{children}</DialogContent>
    </DialogRoot>
  );
};
