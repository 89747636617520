import React from "react";

import { useMediaQuery } from "hooks/useMediaQuery";

import { BreadCrumbNav } from "./bread-crumb-nav";
import { NotificationAndInitials } from "./notification-and-initials";
import { SearchInput } from "./search-input";
import { ITopBarProps } from "./types";

export const TopBar = (props: ITopBarProps) => {
  const { isSideBarCollapsed } = props;
  const isTabDevice = useMediaQuery("(max-width: 900px)");
  const isMobileDevice = useMediaQuery("(max-width: 768px)");
  const isMobileMdDevice = useMediaQuery("(max-width: 650px)");
  const isMobileSmDevice = useMediaQuery("(max-width: 500px)");

  return (
    <div
      className={`bg-white w-full fixed flex-grow transition-all duration-500 ease-out ${isSideBarCollapsed ? "max-w-[calc(100%-76px)] ml-[76px]" : "max-w-[calc(100%-250px)] 2xl:max-w-[calc(100%-400px)] ml-[250px] 2xl:ml-[400px]"} h-16 border-b-[#E5E7EB] border-b-[0.5px] z-[1000] px-6 py-[14px] flex items-center`}
    >
      <div className="h-9 w-full flex items-center justify-between">
        <BreadCrumbNav
          isSideBarCollapsed={isSideBarCollapsed}
          isTabDevice={isTabDevice}
          isMobileDevice={isMobileDevice}
          isMobileMdDevice={isMobileMdDevice}
          isMobileSmDevice={isMobileSmDevice}
        />
        <SearchInput
          isSideBarCollapsed={isSideBarCollapsed}
          isTabDevice={isTabDevice}
          isMobileDevice={isMobileDevice}
        />
        <NotificationAndInitials
          isSideBarCollapsed={isSideBarCollapsed}
          isMobileMdDevice={isMobileMdDevice}
          isMobileSmDevice={isMobileSmDevice}
        />
      </div>
    </div>
  );
};
