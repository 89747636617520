import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { MenuContent, MenuRoot } from "components/ui/menu";

import { profileDropDownMenuContentCss } from "./styles";
import { IProfileDropDownProps, IProfileDropDownRef } from "./types";

export const ProfileDropDown = forwardRef<
  IProfileDropDownRef,
  IProfileDropDownProps
>((props, ref) => {
  const { position, children } = props;
  const [open, setOpen] = useState(false);
  const menuContentRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen(!open);
  };

  useImperativeHandle(ref, () => ({
    isDropDownOpen: open,
    handleToggleDropdown: handleToggle,
    dropDownRef: menuContentRef,
  }));

  return (
    <MenuRoot
      open={open}
      anchorPoint={{ x: position.left, y: position.top }}
    >
      <MenuContent
        css={profileDropDownMenuContentCss}
        ref={menuContentRef}
      >
        {children}
      </MenuContent>
    </MenuRoot>
  );
});
