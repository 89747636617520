import React, { useRef, useState } from "react";

import { ReactComponent as NotificationIcon } from "assets/icons/notification.svg";
import { useAuth } from "contexts/auth-context";
import { useOutsideClick } from "hooks/useOutsideClick";

import { ProfileDropDown } from "../profile-drop-down";
import { ProfileDropDownContent } from "../profile-drop-down/ProfileDropDownContent";
import { IProfileDropDownRef } from "../profile-drop-down/types";
import { INotificationAndInitialsProps } from "./types";

const START_INDEX = 0;
const FILTER_POSITION_YAXIS_OFFSET = -24;
const FILTER_POSITION_XAXIS_OFFSET = 22;

export const NotificationAndInitials = (
  props: INotificationAndInitialsProps
) => {
  const { isSideBarCollapsed, isMobileMdDevice, isMobileSmDevice } = props;
  const { userData, logOut } = useAuth();
  const profileDropDownRef = useRef<IProfileDropDownRef>(null);
  const profileDropDownTrigger = useRef<HTMLDivElement>(null);
  const [profileDropDownPosition, setProfileDropDownPosition] = useState({
    top: 0,
    left: 0,
  });

  const handleToggleProfileDropDown = () => {
    if (!profileDropDownRef.current || !profileDropDownTrigger.current) return;

    const rect = profileDropDownTrigger.current.getBoundingClientRect();
    setProfileDropDownPosition({
      top: rect.top - FILTER_POSITION_YAXIS_OFFSET,
      left: rect.left + FILTER_POSITION_XAXIS_OFFSET,
    });
    profileDropDownRef.current.handleToggleDropdown();
  };

  const handleOutsideClick = () => {
    if (
      !profileDropDownRef.current ||
      !profileDropDownRef.current.isDropDownOpen
    )
      return;
    profileDropDownRef.current.handleToggleDropdown();
  };

  useOutsideClick(
    profileDropDownRef.current?.dropDownRef,
    handleOutsideClick,
    profileDropDownTrigger
  );

  const handleLogOut = () => {
    logOut();
    handleToggleProfileDropDown();
  };

  return (
    <>
      <div
        className={`w-full max-w-[84px] h-[32px] flex justify-between items-center gap-6 ${(isMobileMdDevice && !isSideBarCollapsed) || (isMobileSmDevice && isSideBarCollapsed) ? "hidden" : ""}`}
      >
        <div className="relative basis-7 flex-shrink-0 cursor-pointer">
          <NotificationIcon />
          <div className="absolute bg-[#FF5A5E] text-white font-medium leading-[11.52px] text-[10.11px] flex items-center justify-center w-4 h-4 rounded-[50%] top-0 right-0">
            30
          </div>
        </div>
        <div
          className="bg-[#027BE5] basis-8 flex-shrink-0 h-8 rounded-[50%] font-medium text-[12.8px] leading-[19.2px] flex items-center justify-center  py-[6px] text-white cursor-pointer"
          onClick={handleToggleProfileDropDown}
          ref={profileDropDownTrigger}
        >
          {userData.firstName.charAt(START_INDEX)}
        </div>
      </div>
      <ProfileDropDown
        position={profileDropDownPosition}
        ref={profileDropDownRef}
      >
        <ProfileDropDownContent
          userData={userData}
          handleLogOut={handleLogOut}
        />
      </ProfileDropDown>
    </>
  );
};
