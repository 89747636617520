import React from "react";

import { ISplitScreenProps } from "./types";

export const SplitScreen = (props: ISplitScreenProps) => {
  const {
    firstChild,
    secondChild,
    splitScreenWrapperClassNames,
    firstChildClassNames,
    secondChildClassNames,
  } = props;
  return (
    <div
      className={`flex justify-between flex-col lg:flex-row h-screen w-full 2xl:max-w-[1400px] 2xl:mx-auto ${splitScreenWrapperClassNames ?? ""}`}
    >
      <div
        className={`w-full max-w-[50%] hidden lg:block ${firstChildClassNames ?? ""}`}
      >
        {firstChild}
      </div>
      <div
        className={`w-full max-w-full lg:max-w-[50%] ${secondChildClassNames ?? ""}`}
      >
        {secondChild}
      </div>
    </div>
  );
};
