const SLICE_START_INDEX = 1;
const CHARACTER_POSITION_INDEX = 0;

export const formatPathNameIntoReadableFormat = (route: string): string => {
  return route
    .split("-")
    .map(
      (word) =>
        word.charAt(CHARACTER_POSITION_INDEX).toUpperCase() +
        word.slice(SLICE_START_INDEX)
    )
    .join(" ");
};

export const formatLastPathNameIntoAReadableFormat = (
  route: string
): string => {
  const lastSegment = route.split("/").filter(Boolean).pop();

  if (!lastSegment) return "";
  return formatPathNameIntoReadableFormat(lastSegment);
};

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const TWELVE_HOUR = 12;
const FIRST_DATE_ENDING_WITH_ST = 1;
// const DATE_ENDING_WITH_ST = 21;
const DATE_ENDING_WITH_TH = 20;
const DATE_ENDING_WITH_RD = 3;
const DATE_ENDING_WITH_ND = 2;
const START_OF_MULTI_DIGIT_DATES = 10;
const MINUTES_PADDING = 2;
export const formatDateToDDMMTTSS = (dateString: string): string => {
  const date = new Date(dateString); // Create Date object
  const day = date.getDate(); // Use local date
  const month = monthNames[date.getMonth()]; // Use local month
  const hours = date.getHours(); // Use local time
  const minutes = date.getMinutes();

  // Determine AM/PM
  const period = hours >= TWELVE_HOUR ? "PM" : "AM";
  const formattedHours = hours % TWELVE_HOUR || TWELVE_HOUR;
  const formattedMinutes = minutes.toString().padStart(MINUTES_PADDING, "0");
  const ordinalSuffix = (n: number) => {
    if (n > START_OF_MULTI_DIGIT_DATES && n < DATE_ENDING_WITH_TH) return "th";
    switch (n % START_OF_MULTI_DIGIT_DATES) {
      case FIRST_DATE_ENDING_WITH_ST:
        return "st";
      case DATE_ENDING_WITH_ND:
        return "nd";
      case DATE_ENDING_WITH_RD:
        return "rd";
      default:
        return "th";
    }
  };

  return `${day}${ordinalSuffix(day)} ${month}, ${formattedHours}:${formattedMinutes} ${period}`;
};

export const formatDateToDDMMYYTTSS = (dateString: string): string => {
  const date = new Date(dateString); // Create Date object
  const day = date.getDate(); // Use local date
  const month = monthNames[date.getMonth()]; // Use local month
  const year = date.getFullYear(); // Extract the year
  const hours = date.getHours(); // Use local time
  const minutes = date.getMinutes();

  const period = hours >= TWELVE_HOUR ? "pm" : "am";
  const formattedHours = hours % TWELVE_HOUR || TWELVE_HOUR;
  const formattedMinutes = minutes.toString().padStart(MINUTES_PADDING, "0");

  const ordinalSuffix = (n: number) => {
    if (n > START_OF_MULTI_DIGIT_DATES && n < DATE_ENDING_WITH_TH) return "th";
    switch (n % START_OF_MULTI_DIGIT_DATES) {
      case FIRST_DATE_ENDING_WITH_ST:
        return "st";
      case DATE_ENDING_WITH_ND:
        return "nd";
      case DATE_ENDING_WITH_RD:
        return "rd";
      default:
        return "th";
    }
  };

  return `${day}${ordinalSuffix(day)} ${month}, ${year} at ${formattedHours}:${formattedMinutes}${period}`;
};
