/**
 *
 * Asynchronously loads the component for UiComponentsDemoPage
 *
 */
import { lazyLoad } from "utils";

export const UiComponentsDemoPage = lazyLoad(
  () => import("./index"),
  (module) => module.UiComponentsDemoPage
);
