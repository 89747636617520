import { RefObject, useEffect } from "react";

export const useOutsideClick = (
  ref: RefObject<HTMLElement> | undefined,
  callback: () => void,
  buttonRef?: RefObject<HTMLElement>
): void => {
  useEffect(() => {
    const handleClickOutside = (evt: MouseEvent) => {
      if (!ref) return;
      if (
        ref.current &&
        !ref.current.contains(evt.target as Node) &&
        (!buttonRef?.current || !buttonRef.current.contains(evt.target as Node))
      ) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback, buttonRef]);
};
