import React, { useState } from "react";

import { ReactComponent as SearchIcon } from "assets/icons/search-black.svg";

import { ISearchInputProps } from "./types";

export const SearchInput = (props: ISearchInputProps) => {
  const { isSideBarCollapsed, isTabDevice, isMobileDevice } = props;
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchQueryChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = evt.target;
    setSearchQuery(value);
  };

  return (
    <div
      className={`bg-[#EFF0F1] w-full max-w-[40%] xlg:max-w-[42.3%] h-9 p-2 flex items-center gap-2 rounded-xl relative ${(isTabDevice && !isSideBarCollapsed) || (isMobileDevice && isSideBarCollapsed) ? "hidden" : ""}`}
    >
      <div>
        <SearchIcon />
      </div>
      <input
        placeholder="Search anything..."
        className={`text-black absolute bg-transparent w-[166px] h-5 left-10 placeholder:text-[#9CA3AF] focus:outline-none`}
        onChange={handleSearchQueryChange}
        value={searchQuery}
      />
    </div>
  );
};
