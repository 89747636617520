const BASE_36 = 36;
const SLICE_START_INDEX = 2;
const SLICE_END_INDEX = 7;

export const generateUniqueId = (): string => {
  const timestamp = Date.now().toString(BASE_36);
  const randomNumber = Math.random()
    .toString(BASE_36)
    .slice(SLICE_START_INDEX, SLICE_END_INDEX);
  return `${timestamp}-${randomNumber}`;
};
