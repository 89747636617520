import { customErrorToast, customInfoToast } from "ui/custom-toast";

const START_INDEX = 0;
const DEFAULT_MAX_TEXT_LENGTH = 10;
const MIN_PLURALIZE_TEXT_COUNT = 1;
const INDEX_OFFSET = 1;

export const truncateText = (
  text: string,
  maxLength: number = DEFAULT_MAX_TEXT_LENGTH
) => {
  return text.length > maxLength
    ? `${text.slice(START_INDEX, maxLength)}…`
    : text;
};

export const pluralizeText = (
  text: string,
  count: number,
  suffix: string = ""
): string => {
  if (text !== "" && count > MIN_PLURALIZE_TEXT_COUNT) {
    return `${text}${suffix !== "" ? suffix : "s"}`;
  }
  return text;
};

export const getRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + INDEX_OFFSET)) + min;
};

export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(START_INDEX).toUpperCase() + text.slice(INDEX_OFFSET);
};

export const handleCopyToClipboard = async (
  text: string,
  successText: string = "Text copied to clipboard!"
): Promise<void> => {
  try {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      await navigator.clipboard.writeText(text);
      customInfoToast({
        title: "Copy to Clipboard",
        message: successText,
      });
    } else {
      // Fallback for older browsers
      const textarea = document.createElement("textarea");
      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      customInfoToast({
        title: "Copy to Clipboard",
        message: successText,
      });
      document.body.removeChild(textarea);
    }
  } catch (err) {
    console.error("Failed to copy text to clipboard:", err);
    customErrorToast({
      title: "Copy to Clipboard",
      message: "Failed to copy text to clipboard, please try again",
    });
  }
};

export const capitalizeWords = (input: string): string => {
  return input
    .split(" ")
    .map(
      (word) =>
        word.charAt(START_INDEX).toUpperCase() +
        word.slice(INDEX_OFFSET).toLowerCase()
    )
    .join(" ");
};

export const splitStringAtPosition = (
  inputString: string,
  position: number
): string[] => {
  const part1 = inputString.slice(START_INDEX, position); // From the start to the position (exclusive)
  const part2 = inputString.slice(position); // From the position to the end
  return [part1, part2];
};
