const epColor = {
  ep: "#00535C",
  "ep-black": "#1C1C1C",
  "ep-blue": "#027BE5",
  "ep-deep-black": "#1C1C1C",
  "ep-field-green": "#007928",
  "ep-green-lite": "##F7FFFB",
  "ep-red": "#EF4444",
  "ep-red-lite": "#FEF2F2",
  "ep-50": "#E5FDFF",
  "ep-100": "#ABD9DC",
  "ep-200": "#72B3B8",
  "ep-300": "#398D94",
  "ep-400": "#006770",
  "ep-500": "#00535C",
  "ep-600": "#003F46",
  "ep-700": "#002B30",
  "ep-800": "#00171A",
  "ep-gray": "#718096",
  "ep-gray-50": "#F3F4F6",
  "ep-gray-100": "#E5E7EB",
  "ep-gray-200": "#D1D5DB",
  "ep-gray-300": "#9CA3AF",
  "ep-gray-400": "#718096",
  "ep-gray-500": "#4A5564",
  "ep-gray-600": "#374151",
  "ep-gray-700": "#1F2937",
  "ep-gray-800": "#1A202C",
  "ep-green": "#A4EDD8",
  "ep-green-50": "#EAFBF6",
  "ep-green-100": "#DAF9F0",
  "ep-green-200": "#C8F5E8",
  "ep-green-300": "#B6F1E0",
  "ep-green-400": "#A4EDD8",
  "ep-green-500": "#7CB7A6",
  "ep-green-600": "#548174",
  "ep-green-700": "#2C4B42",
  "ep-green-800": "#041510",
  "ep-navy": "#193F52",
  "ep-navy-50": "#EBF5F9",
  "ep-navy-100": "#B8C9D0",
  "ep-navy-200": "#839BA6",
  "ep-navy-300": "#4E6D7C",
  "ep-navy-400": "#193F52",
  "ep-navy-500": "#153344",
  "ep-navy-600": "#102734",
  "ep-navy-700": "#0B1B24",
  "ep-navy-800": "#060F14",
  "ep-orange": "#F49D1B",
  "ep-orange-50": "#FEF5E7",
  "ep-orange-100": "#FDDFB4",
  "ep-orange-200": "#FAC981",
  "ep-orange-300": "#F7B34E",
  "ep-orange-400": "#F49D1B",
  "ep-orange-500": "#BD7B16",
  "ep-orange-600": "#86570F",
  "ep-orange-700": "#4F3308",
  "ep-orange-800": "#180F01",
  "ep-yellow": "#FEEB97",
  "ep-yellow-50": "#FFFAE6",
  "ep-yellow-100": "#FEF7D3",
  "ep-yellow-200": "#FEF3BF",
  "ep-yellow-300": "#FEEFAB",
  "ep-yellow-400": "#FEEB97",
  "ep-yellow-500": "#C4B772",
  "ep-yellow-600": "#8B814C",
  "ep-yellow-700": "#524B26",
  "ep-yellow-800": "#191500",
};

export { epColor };
