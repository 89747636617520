import { QueryClient } from "@tanstack/react-query";

const CACHE_TIME = 300000; // 1000 * 60 * 5;
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2, // Retry failed requests twice
      refetchOnWindowFocus: false, // Prevent refetching on window focus (optional)
      staleTime: CACHE_TIME, // Cache data for 5 minutes before considering it stale
    },
  },
});
