import React from "react";

import { SplitScreen } from "ui/split-screen";

import { ImageSectionWithText } from "./image-section-with-text";
import { IAuthLayoutProps } from "./types";

export const AuthLayout = (props: IAuthLayoutProps) => {
  const { children } = props;
  return (
    <div>
      <SplitScreen
        firstChild={<ImageSectionWithText />}
        secondChild={
          <div className="py-24 min-h-screen w-full max-w-[95%] md:max-w-[60%] lg:max-w-[466px] mx-auto">
            {children}
          </div>
        }
      />
    </div>
  );
};
