import React from "react";

import { MainRoutes } from "routes";

export const App = () => {
  return (
    <>
      <MainRoutes />
    </>
  );
};
