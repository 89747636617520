import styled from "@emotion/styled";

import { ITypographyProps } from "./types";

const VERY_BOLD_FW = 900;
const BOLD_FW = 700;
const MEDIUM_FW = 500;
const NORMAL_FW = 400;

export enum FontWeight {
  VERY_BOLD = VERY_BOLD_FW,
  BOLD = BOLD_FW,
  MEDIUM = MEDIUM_FW,
  NORMAL = NORMAL_FW,
}

export const Heading1 = styled.h1<ITypographyProps>`
  font-size: ${(props) => props.fontSize ?? "26px"};
  line-height: ${(props) => props.lineHeight ?? "30px"};
  color: ${(props) => props.textColor ?? "#000000"};
  font-weight: ${(props) => props.fontWeight ?? FontWeight.VERY_BOLD};
`;

export const Heading2 = styled.h2<ITypographyProps>`
  font-size: ${(props) => props.fontSize ?? "24px"};
  line-height: ${(props) => props.lineHeight ?? "28px"};
  color: ${(props) => props.textColor ?? "#000000"};
  font-weight: ${(props) => props.fontWeight ?? FontWeight.BOLD};
`;

export const Heading3 = styled.h3<ITypographyProps>`
  font-size: ${(props) => props.fontSize ?? "20px"};
  line-height: ${(props) => props.lineHeight ?? "23px"};
  color: ${(props) => props.textColor ?? "#000000"};
  font-weight: ${(props) => props.fontWeight ?? FontWeight.MEDIUM};
`;

export const Heading4 = styled.h4<ITypographyProps>`
  font-size: ${(props) => props.fontSize ?? "16px"};
  line-height: ${(props) => props.lineHeight ?? "18px"};
  color: ${(props) => props.textColor ?? "#000000"};
  font-weight: ${(props) => props.fontWeight ?? FontWeight.NORMAL};
`;

export const Paragraph1 = styled.p<ITypographyProps>`
  font-size: ${(props) => props.fontSize ?? "18px"};
  line-height: ${(props) => props.lineHeight ?? "20.52px"};
  color: ${(props) => props.textColor ?? "#000000"};
  font-weight: ${(props) => props.fontWeight ?? FontWeight.MEDIUM};
`;

export const Paragraph2 = styled.p<ITypographyProps>`
  font-size: ${(props) => props.fontSize ?? "16px"};
  line-height: ${(props) => props.lineHeight ?? "18.24px"};
  color: ${(props) => props.textColor ?? "#000000"};
  font-weight: ${(props) => props.fontWeight ?? FontWeight.NORMAL};
`;

export const Paragraph3 = styled.p<ITypographyProps>`
  font-size: ${(props) => props.fontSize ?? "14px"};
  line-height: ${(props) => props.lineHeight ?? "15.96px"};
  color: ${(props) => props.textColor ?? "#000000"};
  font-weight: ${(props) => props.fontWeight ?? FontWeight.NORMAL};
`;

export const Paragraph4 = styled.p<ITypographyProps>`
  font-size: ${(props) => props.fontSize ?? "12px"};
  line-height: ${(props) => props.lineHeight ?? "13.68px"};
  color: ${(props) => props.textColor ?? "#000000"};
  font-weight: ${(props) => props.fontWeight ?? FontWeight.NORMAL};
`;
