import React from "react";

import { useAuth } from "contexts/auth-context";
import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "routes/constants";

import { INoAuthGuardProps } from "./types";

export const NoAuthGuard = (props: INoAuthGuardProps) => {
  const { children, redirectPath } = props;
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return (
      <Navigate
        to={redirectPath ?? ROUTES.DASHBOARD_PAGE}
        replace
      />
    );
  }

  return (
    <>
      <>{children}</>
      <Outlet />
    </>
  );
};
