/**
 *
 * Asynchronously loads the component for BusinessCompliancePage
 *
 */
import { lazyLoad } from "utils";

export const BusinessCompliancePageLazy = lazyLoad(
  () => import("./index"),
  (module) => module.BusinessCompliancePage
);
