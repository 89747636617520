import { useEffect } from "react";

import { useLocation } from "react-router-dom";

const TOP_POSITION_X = 0;
const TOP_POSITION_Y = TOP_POSITION_X;

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(TOP_POSITION_X, TOP_POSITION_Y);
  }, [pathname]);

  return null;
};
